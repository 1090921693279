<form [formGroup]="form" (ngSubmit)="onSubmit()" style="margin: 12px;">
    <div class="row">

        <div class="col-12">

            <h3>Datos del usuario</h3>

            <table class="table table-bordered">
                <tr>
                    <th>Nombre</th>
                    <td><input value={{data.name}} type="text" formControlName="name" class="form-control" /></td>
                </tr>
                <tr>
                    <th>Nombre de Usuario</th>
                    <td><input value={{data.username}} type="text" formControlName="username" class="form-control" />
                    </td>
                </tr>
                <tr>
                    <th>Contraseña</th>
                    <td>
                        <input type="password" formControlName="password" class="form-control" />

                    </td>
                </tr>
            </table>

        </div>
        <div class="col-12">
            <h3>Fecha</h3>
            <table class="table table-bordered">
                <tr>
                    <th>Fecha de creacion</th>
                    <td>{{data.creado}}</td>
                </tr>
                <tr>
                    <th>Fecha de Modificacion</th>
                    <td>{{data.modificado}}</td>
                </tr>
            </table>
        </div>

        <div class="container-fluid mt-3">
            <button (click)="close.emit()" class=" ml-auto btn btn-danger ">
                volver
            </button>
            <button type="submit" class=" float-right btn btn-success  ">
                Guardar cambios
            </button>

        </div>

    </div>
</form>

