import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DebugsService } from 'src/app/_service/debugs.service';
import { Log } from 'src/app/_class/log';
import { Pagination } from 'src/app/_class/pagination';
import { Debug } from 'src/app/_class/debug';
import { ActivatedRoute } from '@angular/router';
import { DevicesService } from '../_service/devices.service';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-viw-debug-device',
  templateUrl: './viw-debug-device.component.html',
  styleUrls: ['./viw-debug-device.component.scss']
})
export class ViwDebugDeviceComponent implements OnInit {
  busqueda
  dataDevice
  data;
  id
  debug: Debug;
  logs: Log[] = []
  page: Pagination = new Pagination({ sort: '-id', perPage: 200 })

  showTrc = false

  constructor(
    private route: ActivatedRoute,
    private debugService: DebugsService,
    private devicesservice:DevicesService
  ) { }

  async ngOnInit() {
    this.id=this.route.snapshot.paramMap.get('id')
    //obtener el dispositivo
    this.dataDevice = await this.devicesservice.getdevice(this.id).toPromise();

   //obtener todos los datos de la tabla
    this.data = await (await this.getdebug()).filter(data=> data.dev_id==this.dataDevice.id)
      
    console.log("dispositivo "+this.dataDevice)
    console.log(this.data[0].id)
   
      this.debug= { id: this.data[0].id}
      console.log(this.debug)
      const [debugs, logs] = await Promise.all([this.debugService.getDebug(this.debug.id).toPromise(), this.getData()])
      this.debug = debugs
      this.logs = logs

      console.log(this.logs)
 
  }

  async getdebug() {
    const { body, headers } = await this.debugService.getDebugs(this.page.params).toPromise();
    this.page.Load(headers)
    return body;
  }

  async getData() {
    const { body, headers } = await this.debugService.getLogs(this.debug.id, this.page.params).toPromise();
    this.page.Load(headers)
    return body
  }

  textColorMap = {
    'Log': 'text-dark',
    'Warning': 'text-warning',
    'Error': 'text-danger',
    'Exception': 'text-danger'
  }
  textColor(name: string) {
    if (this.textColorMap[name])
      return this.textColorMap[name];
    else {
      console.log({ name })
      return 'text-danger'
    }
  }

  time(logTime: number) {
    let diff = logTime - this.debug.creado
    if (diff < 0) {
      return 'INICIO'
    } else {
      return this.secondsToString(diff)
    }
  }

  async onScroll() {
    if (this.page.total > this.logs.length) {

      this.page.page++;
      this.logs.push(...await this.getData())
    } else {
      console.log("lastPage")
    }
  }

  scrollState = {
    show: false,
    onShow: 400,
    onHide: 200
  }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if ((window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop) > this.scrollState.onShow) {
      this.scrollState.show = true;
    } else if (this.scrollState.show &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop)
      < this.scrollState.onHide) {
      this.scrollState.show = false;
    }
  }

  secondsToString(seconds) {
    var hour = Math.floor(seconds / 3600);
    var minute = Math.floor((seconds / 60) % 60);
    var second = seconds % 60;
    return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`
  }

  sort = {
    name: 'id',
    order: true,
    menu: [
      { sort: 'id' },
      { sort: 'msg' }
    ]
  }


  async setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop
      this.sort.order = true
    } else {
      this.sort.order = !this.sort.order
    }
    this.page.page = 1
    this.page.sort = `${this.sort.order ? '-' : ''}${this.sort.name}`;
    this.logs = await this.getData();
  }
  recargar(){
    this.busqueda=""
  }
}
