<div class="container ">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="name">Nombre :</label>
            <input type="text" formControlName="name" class="form-control" />
        </div>
        <div class="form-group">
            <label for="username">Nombre usuario:</label>
            <input type="text" formControlName="username" class="form-control" />
        </div>
        <div class="form-group">
            <label for="password">Contraseña:</label>
            <input type="password" formControlName="password" class="form-control"/>
        </div>
        <div class="form-group">
            <button  (click)="close.emit()"  class="btn btn-danger">
                volver
            </button>
            <button  type="submit"  class="btn btn-primary ml-2">
                Registrar
            </button>
           <!-- <a routerLink="../login" class="btn btn-link">Cancel</a>-->
        </div>
    </form>
</div>


