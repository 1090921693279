<form >
    <h2 class="col-md-3" for="appCompany">Filtrar Empresa</h2>
    <div class="form-group ml-1 ">
        <div class="form-group col-md-6 float-left">
            <ng-select  name="appCompany" [items]="form.select.app_company" [multiple]="false"
                [closeOnSelect]="true" [hideSelected]="true" placeholder="Selecciona el nombre de la empresa"
                [(ngModel)]="form.input.app_company">
            </ng-select>
        </div>
        <button (click)="recargar()" class="float-left btn btn-primary btn btn-primary text-white ">Limpiar Busqueda</button>
        
    </div>
</form>




<div class="row col ml-1" infinite-scroll (scrolled)="onScroll()">
    <table class="table table-bordered table-striped table-hover">
        <thead>
            <tr class="text-primary">
                <th> Id</th>
                <th>App</th>
                <th>Unity</th>
                <th>Empresa</th>
                <th>Dispositivo</th>
                <th>Fecha</th>


            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let debugs of data |  filter:form.input.app_company"  [routerLink]="['/debugs',debugs.id]" [ngStyle]="{ cursor: 'pointer'}">
                <td>{{debugs.id}}</td>
                <td>
                    {{debugs.app_name}}
                    {{debugs.app_version}}

                </td>
                <td>
                    {{debugs.unity_version}}
                </td>
                <td>
                    {{debugs.app_company}}
                </td>
                <td>
                    {{debugs.device.name}}
                    {{debugs.device.alter_name}}

                </td>
                
                    <td>{{debugs.creado*1000|date:"dd-MM-yyyy"}}  {{ debugs.creado*1000|date:"HH:mm:ss"}} </td>
                
                <!--
                <td>
                    <button type="button" class="btn btn-success btn-block" [routerLink]="['/debugs',debugs.id]"> Abrir
                    </button>
                </td>
                -->
            </tr>
        </tbody>
    </table>
</div>
<button [ngClass]="'no-hidden'" *ngIf="scrollState.show" class="btn btn-primary" (click)="scrollTop()">
    <fa-icon [icon]="['fas','arrow-up']"></fa-icon>
</button>
