import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DebugsService } from 'src/app/_service/debugs.service';

@Component({
  selector: 'app-debug-search',
  templateUrl: './debug-search.component.html',
  styleUrls: ['./debug-search.component.scss']
})
export class DebugSearchComponent implements OnInit {

  Filter:[]

  form = {
    input: {
      app_name: null,
      app_platform: null,
      app_version: null,
      app_company: null,
      unity_version: null
    },
    select: {
      app_name: [],
      app_platform: [],
      app_version: [],
      app_company: [],
      unity_version: []
    }
  }

  @Output()
  dataFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  limpio: EventEmitter<any> = new EventEmitter<any>();

  constructor(private debugService: DebugsService,
   private toastrservice:ToastrService) { }

  ngOnInit(): void {
    this.debugService.getFilters().subscribe(data => this.form.select = data)
  }


  limpiarfiltro() {
    this.form.input.app_company=null
    this.form.input.app_name=null
    this.form.input.app_platform=null
    this.form.input.app_version=null
    this.form.input.unity_version=null
    this.limpio.emit("");
    this.toastrservice.info("Campos limpios")


  }

  onSubmit() {
    
    this.dataFilter.emit(this.form.input)

  }
}
