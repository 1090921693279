<div class="row">

    <div class="col">
        <!--routerLink="/new-user"-->
        <h2 class=" mb-3">Usuarios</h2>
        <h6>Buscar usuario</h6>
        <button class="btn btn-primary float-right mb-3" (click)="newUser(newuser,{size:'xl'})"><svg
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                <path fill-rule="evenodd"
                    d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
            </svg> Nuevo usuario</button>

        <form class="form-inline">

            <div class="form-group sm-3 mb-2">

                <input [(ngModel)]="usuario" name="usuario" type="text" class="form-control">

            </div>

            <a (click)="limpiar()" class="btn btn-primary ml-2 mb-2 text-white">Limpiar</a>

        </form>
        <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
                <thead>
                    <tr class="text-primary">
                        <th> Id</th>
                        <th>Nombre de usuario</th>
                        <th>Nombre</th>
                        <th>Scope</th>
                        <th>Fecha creado</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of data | filter:usuario">
                        <td>{{user.id}}</td>
                        <td>
                            {{user.username}}
                        </td>
                        <td>
                            {{user.name}}
                        </td>
                        <td *ngIf="user.scope">
                            {{user.scope}}
                        </td>
                        <td *ngIf="!user.scope">
                            Null
                        </td>
                        <td>
                            {{user.creado| date}}
                        </td>
                        <td class="text-center">
                            <!--[routerLink]="['/users',user.id]"-->
                            <button (click)="verUsuario(verusuario,user.id,{size:'xl'})" class="btn btn-success "><svg
                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path
                                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg> Ver</button>
                            <!--[routerLink]="['/users/editar',user.id]"-->
                            <button (click)="editarUsuario(editarusuario,user.id,{size:'xl'})"
                                class="btn btn-primary ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                </svg> Modificar</button>
                            <button (click)="delete(user.id)" class="btn btn-danger ml-2"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-person-x-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                                </svg> Eliminar</button>

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!--ver usuario-->
<ng-template #verusuario let-modal>

    <div class="mb-3 mt-2">
        <div class="col-12">
            <h3>Datos del usuario</h3>
            <table class="table table-bordered">
                <tr>
                    <th>Nombre</th>
                    <td>{{dataUsuario.name}}</td>
                </tr>
                <tr>
                    <th>Nombre de Usuario</th>
                    <td>{{dataUsuario.username}}</td>
                </tr>
                <tr>
                    <th>Contraseña</th>
                    <td>{{contrasenia}}</td>
                </tr>
            </table>
        </div>
        <div class="col-12">
            <h3>Fecha</h3>
            <table class="table table-bordered">
                <tr>
                    <th>Fecha de creacion</th>
                    <td>{{dataUsuario.creado|date}}</td>
                </tr>
                <tr>
                    <th>Fecha de Modificacion</th>
                    <td>{{dataUsuario.modificado|date}}</td>
                </tr>
            </table>
            <button (click)="close()" class="btn btn-danger">
                Cerrar
            </button>
        </div>
    </div>

</ng-template>

<!--modificar usuario-->
<ng-template #newuser let-modal>

    <app-debug-newuser (close)="close()">

    </app-debug-newuser>

</ng-template>

<ng-template #editarusuario let-modal>

    <app-editar-usuario [id]="idEditar" (close)="close()"></app-editar-usuario>

</ng-template>