import { Component, OnInit } from '@angular/core';
import { DebugsService } from '../_service/debugs.service';
import { Debug } from 'src/app/_class/debug';
import { Subscription } from 'rxjs';
import { AuthService } from '../_service/auth.service';
import { Pagination } from '../_class/pagination';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

  data: Debug[] = [];
  largo: number;
  page: Pagination = new Pagination({ expand: ['device'], sort: '-id', perPage: 500 })

  form = {
    input: {

      app_company: null,
    },
    select: {
      app_company: [],

    }
  }




  constructor(
    private authService: AuthService,
    private debugsservices: DebugsService,
    private toastrservice: ToastrService,

  ) { }

  subLogin: Subscription = null;
  async ngOnInit() {

    
    this.debugsservices.getFilters().subscribe(data => this.form.select = data);
        this.subLogin = this.authService.onLoginSuccess.subscribe(async () => {
          
        })
        if (this.authService.isValid) {
        
            this.data = await this.getData()
            
        }
  }
  async getData() {
    const { body, headers } = await this.debugsservices.getDebugs(this.page.params).toPromise();
    this.page.Load(headers)
    return body;
  }
  async onScroll() {

    if (this.page.total > this.data.length) {

      this.page.page++;
      this.data.push(...await this.getData())
    } else {
      console.log("lastPage")
    }
    

    
  }
  scrollState = {
    show: false,
    onShow: 400,
    onHide: 200
  }
  recargar(){
    this.form.input.app_company=null
  }
  
 

}
