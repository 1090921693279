import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'


import { ToastrModule } from 'ngx-toastr';
import { NavbarComponent } from './page/navbar/navbar.component';
import { LoginComponent } from './page/login/login.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { DebugAdminComponent } from './debug/debug-admin/debug-admin.component';
import { DebugViewComponent } from './debug/debug-view/debug-view.component';
import { UserAdminComponent } from './user/user-admin/user-admin.component';
import { DebugSearchComponent } from './debug/debug-search/debug-search.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { NgbModalModule, NgbCollapseModule, NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';



import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faDesktop, faMobile, faBug, faCat, faSort, faSortUp, faSortDown, faUserCog, faUserCircle, faMugHot, faInfoCircle, faExclamationTriangle, faExclamationCircle, faArrowUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faWindows, faAndroid, faLinux, faChrome, faUnity } from "@fortawesome/free-brands-svg-icons";

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DebugNewuserComponent } from './debug/debug-newuser/debug-newuser.component';
import { EditarUsuarioComponent } from './editar-usuario/editar-usuario.component';
import { VerUsuarioComponent } from './ver-usuario/ver-usuario.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { DevicesComponent } from './devices/devices.component';
import { ViwDebugDeviceComponent } from './viw-debug-device/viw-debug-device.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

@NgModule({
  declarations: [
    
    AppComponent,
    NavbarComponent,
    LoginComponent,
    NotFoundComponent,
    DebugAdminComponent,
    DebugViewComponent,
    UserAdminComponent,
    DebugSearchComponent,
    MainLayoutComponent,
    DebugNewuserComponent,
    EditarUsuarioComponent,
    VerUsuarioComponent,
    EmpresasComponent,
    DevicesComponent,
    ViwDebugDeviceComponent,
  ],
  imports: [
    
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,

    ToastrModule.forRoot({
      preventDuplicates: true
    }),

    NgbModalModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbPaginationModule,

    FontAwesomeModule,

    LoadingBarHttpClientModule,
    LoadingBarModule,

    InfiniteScrollModule


  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSearch, faArrowUp, faInfoCircle, faExclamationTriangle, faExclamationCircle, faMugHot, faUserCog, faUserCircle, faDesktop, faMobile, faBug, faCat, faSort, faSortUp, faSortDown, faWindows, faAndroid, faLinux, faChrome, faUnity)
  }
}