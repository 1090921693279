<div class="row">
    <div class="col-12">
        <h3>Dispositivo</h3>
        <table class="table table-bordered">
            <tr>
               
                <th>Nombre</th>
                <td>{{dataDevice?.name}} {{dataDevice?.alter_name}}</td>
                <th>Tipo</th>
                <td>{{dataDevice?.type}}</td>
            </tr>
            <tr>
                <th>Id</th>
                <td>{{dataDevice?.id}}</td>
                <th>Keycode</th>
                <td>{{dataDevice?.keycode}}</td>
                <th>Opciones</th>
                <td colspan="2"><button class="btn btn-sm btn-primary" (click)="showTrc=!showTrc">Mostrar
                        rastro</button></td>
                       
            </tr>
        </table>
    </div>
</div>
<h2>Filtrar log</h2>
    <form class="form-inline">

      <div class="form-group sm-3 mb-2">

        <input [(ngModel)]="busqueda" name="busqueda" type="text" class="form-control" >

      </div>
      <a (click)="recargar()" class="btn btn-primary ml-2 mb-2 text-white">Limpiar</a>

      

    </form>

<div class="row" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
    <div class="col">
        <div class="table-responsive">
            <table class="table table-bordered ">
                <thead>
                    <tr>
                        <th tiempo
                        [width]="120" [ngClass]="{ 'text-primary': sort.name == 'id' }"
                            [ngStyle]="{ cursor: 'pointer'}" (click)="setOrder('id')">
                         Tiempo
                        </th>
                        <th [ngClass]="{ 'text-primary': sort.name == 'msg' }" [ngStyle]="{ cursor: 'pointer'}"
                            (click)="setOrder('msg')">
                          Mensaje
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of logs |filter:busqueda">
                        <td>{{time(log.creado)}}</td>
                        <td>
                            <pre
                                [ngClass]="textColor(log.type)"> {{log.type}} {{log.msg}} <p *ngIf="showTrc" class="text-muted">{{log.trc}}</p></pre>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<button [ngClass]="'no-hidden'" *ngIf="scrollState.show" class="btn btn-primary" (click)="scrollTop()">
    <fa-icon [icon]="['fas','arrow-up']"></fa-icon>
</button>
