import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../_service/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('content', { static: true })

  private contentTpl: TemplateRef<any>;
  openModal = false;

  username = ''
  password = ''

  modalRef: NgbModalRef;

  constructor(
    private session: AuthService,
    private modalService: NgbModal,
    private toast: ToastrService
  ) { }

  timerLogin;

  ngOnInit() {

    if (this.session.isExpire) {
      this.open();
    }

    this.session.onLoginSuccess.subscribe((session) => {
      this.toast.success(`Bienvenido ${session.scope.name}`);
      this.modalRef.close();
      this.openModal = false;
    });

    this.session.onLoginFail.subscribe(() => {
      this.toast.warning("Usuario o contraseña invalida")
    })

    this.session.onLogoutSuccess.subscribe(() => {
      this.open();
    })
  }

  onSubmit() {
    this.session.Login(this.username, this.password);
  }

  open() {
    if (!this.openModal) {
      this.modalRef = this.modalService.open(this.contentTpl, {
        backdrop: 'static',
        keyboard: false,
        size: 'sm'
      });
      this.openModal = true;
    }
  }
}
