import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DebugsService } from 'src/app/_service/debugs.service';
import { Log } from 'src/app/_class/log';
import { Pagination } from 'src/app/_class/pagination';
import { Debug } from 'src/app/_class/debug';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-debug-view',
  templateUrl: './debug-view.component.html',
  styleUrls: ['./debug-view.component.scss']
})
export class DebugViewComponent implements OnInit {
  c:any
  agrup=[]
  busqueda: string
  @Input('debug') debug: Debug;
  tiemporeal = false;
  agrupardata=false;
  log: Log[] = []
  page: Pagination = new Pagination({ sort: '-id', perPage: 200 })
  timerInterval
  timerInterva2
  //colapser

  unicoElemento = []
  vecesRepetidas = []
  contador = 1

  //aqui
  showTrc = false

  constructor(
    private route: ActivatedRoute,
    private debugService: DebugsService,
  ) { }


  async ngOnInit() {

    if (this.debug) {
      this.log = await this.getData()

    } else {
      this.debug = { id: +this.route.snapshot.paramMap.get('id') }
      const [debugs, log] = await Promise.all([this.debugService.getDebug(this.debug.id, { expand: "device" }).toPromise(), this.getData()])
      this.debug = debugs
      this.log = log
    }


    this.agrupar();





  }

  async getData() {
    const { body, headers } = await this.debugService.getLogs(this.debug.id, this.page.params).toPromise();
    this.page.Load(headers)
    return body
  }
  async onScroll() {
    if (this.page.total > this.log.length) {

      this.page.page++;
      this.log.push(...await this.getData())
    } else {
      console.log("lastPage")
    }
    this.agrupar()
  }

  iconMap = {
    'Log': ['fas', 'info-circle'],
    'Warning': ['fas', 'exclamation-triangle'],
    'Error': ['fas', 'exclamation-circle'],
    'Exception': ['fas', 'exclamation-circle'],
    'sort': ['fas', 'sort'],
    'sort-up': ['fas', 'sort-up'],
    'sort-down': ['fas', 'sort-down'],
  }

  icon(name: string) {
    if (this.iconMap[name])
      return this.iconMap[name];
    else {
      return ['fas', 'cat']
    }
  }

  textColorMap = {
    'Log': 'text-dark',
    'Warning': 'text-warning',
    'Error': 'text-danger',
    'Exception': 'text-danger'
  }

  textColor(name: string) {
    if (this.textColorMap[name])
      return this.textColorMap[name];
    else {
      console.log({ name })
      return 'text-danger'
    }
  }

  time(logTime: number) {
    let diff = logTime - this.debug.creado
    if (diff < 0) {
      return 'INICIO'
    } else {
      return this.secondsToString(diff)
    }
  }



  scrollState = {
    show: false,
    onShow: 400,
    onHide: 200
  }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if ((window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop) > this.scrollState.onShow) {
      this.scrollState.show = true;
    } else if (this.scrollState.show &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop)
      < this.scrollState.onHide) {
      this.scrollState.show = false;
    }
    
  }

  secondsToString(seconds) {
    var hour = Math.floor(seconds / 3600);
    var minute = Math.floor((seconds / 60) % 60);
    var second = seconds % 60;
    return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`
  }

  sort = {
    name: 'id',
    order: true,
    menu: [
      { sort: 'id' },
      { sort: 'msg' }
    ]
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? this.icon('sort-up') : this.icon('sort-down')
    } else {
      return this.icon('sort')
    }
  }

  async setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop
      this.sort.order = true
    } else {
      this.sort.order = !this.sort.order
    }
    this.page.page = 1
    this.page.sort = `${this.sort.order ? '-' : ''}${this.sort.name}`;
    this.log = await this.getData();
  }


  recargar() {
    this.busqueda = ""
  }

  async datatiemporeal() {
    

    this.timerInterval = setInterval(async () => {
      if (this.debug) {
        this.log = await this.getData()

      } else {
        this.debug = { id: +this.route.snapshot.paramMap.get('id') }
        const [debugs, logs] = await Promise.all([this.debugService.getDebug(this.debug.id, { expand: "device" }).toPromise(), this.getData()])
        this.debug = debugs
        this.log = logs
      }
      
  
    }, 2000);

    this.timerInterva2= setInterval(async () => {

      this.activar(false)
      clearInterval(this.timerInterval);
      clearInterval(this.timerInterva2);
    }, 300000);
   
  }

  


  activar(estado) {
    this.tiemporeal = estado

    if (this.tiemporeal) {
      console.log("tiempo real activado")
      this.datatiemporeal();
      this.agrupar();
     

    }
    else {
      clearInterval(this.timerInterval);
      console.log("tiempo real desactivado")
      this.getData();


    }
   
  }
  agrupar() {
    
    
    var arr_aux = this.log
    this.unicoElemento = []
    this.vecesRepetidas = []
    this.agrup=[]

    for (let index = 1; index < this.log.length; index++) {

      if (arr_aux[index - 1].msg === this.log[index].msg) {
        console.log("verdadero")
        this.contador++
      }
      else {

          console.log("falso")
          //antes
          // this.unicoElemento.push(this.log[index-1].msg);
          // this.vecesRepetidas.push(this.contador)
          //actual
          this.c = {
                log: this.log[index-1].msg,
                repetido: this.contador,
                type: this.log[index-1].type,
               }
              
              this.agrup.push(this.c) 

          this.contador = 1 
      }

      if (this.log.length == index + 1) {
        console.log("guardado")
        //antes
        // this.unicoElemento.push(this.log[index].msg);
        // this.vecesRepetidas.push(this.contador)
        //actual
        this.c = {
              log: this.log[index].msg,
              repetido: this.contador,
              type: this.log[index-1].type,
             }
            
            this.agrup.push(this.c) 


        this.contador = 1
      }
    }
    console.log("arreglos")
    console.log(this.unicoElemento)
    
    console.log(this.vecesRepetidas)

  
    console.log("juntos")
    console.log(this.agrup)


  }


}
