import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Pagination } from "../../_class/pagination";
import { UserService } from 'src/app/_service/user.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_service/auth.service'; 
import { from, Subscription } from 'rxjs';
import { User } from 'src/app/_class/user';


@Component({
  selector: 'app-debug-newuser',
  templateUrl: './debug-newuser.component.html',
  styleUrls: ['./debug-newuser.component.scss']
})
export class DebugNewuserComponent implements OnInit {
  form: FormGroup;
  @Output() close: EventEmitter<any> = new EventEmitter();
  data: User[] = []
  page: Pagination = new Pagination({ sort: 'id' })


  constructor(
    private formBuilder: FormBuilder,
    private userservice: UserService,
    private authService:AuthService
  ) { }

  subLogin: Subscription = null
  async ngOnInit() {

    this.form = this.formBuilder.group({
      name: [''],
      username: [''],
      password: ['']
    });

    this.subLogin = this.authService.onLoginSuccess.subscribe(async () => {
      this.data = await this.getData()
    })
    if (this.authService.isValid) {
      this.data = await this.getData()
    }
  }   

  async getData() {
    const { body, headers } = await this.userservice.getUsers(this.page.params).toPromise();
    this.page.Load(headers)
    return body;
  }


  onSubmit() {
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].username==this.form.value.username) {
        console.log(this.data[index].username)
        window.alert("Nombre de usuario no disponible");
        return;
      }
      
    }

    if (this.form.value.name =="" && this.form.value.username=="" && this.form.value.password=="") {
      window.alert("Por favor rellene todos los campos");
      return;
    }
    if (this.form.value.name =="" ) {
      window.alert("Por favor ingrese un nombre");
      return;
    }

    if ( this.form.value.username=="" ) {
      window.alert("Por favor ingrese un nombre de usuario");
      return;
    }
    if (this.form.value.password=="") {
      window.alert("Por favor ingrese una contraseña contraseña");
      return;
    }


    this.userservice.Create(this.form.value).subscribe({
      next: () => {

        window.alert("usuario creado con exito");
        window.location.reload();
        return;

      },
      error: error => {
        console.log(error);
        window.alert("Por favor rellene todos los campos");


      }
    });
  }

}
