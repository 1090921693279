import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessionState } from '../_class/session-state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private resource = 'auth';
  private _session: SessionState = null;
  private keyState = `${environment.client.name}_session`

  /**
   * Eventos
   */
  onLoginSuccess: EventEmitter<any> = new EventEmitter();
  onLoginFail: EventEmitter<any> = new EventEmitter();
  onLogoutSuccess: EventEmitter<any> = new EventEmitter();
  onLogoutFail: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
  ) {
    if (localStorage[this.keyState] !== undefined) {
      this._session = JSON.parse(localStorage[this.keyState]);
    }
  }

  async Login(username: string, password: string) {
    try {
      const form = { username, password };
      this._session = await this.http.post<any>(`${environment.endpoint}/${this.resource}/token`, form).toPromise();
      localStorage[this.keyState] = JSON.stringify(this._session);
      this.onLoginSuccess.next(this._session);
    } catch (error) {
      this.onLoginFail.next(error);
    }
  }

  Logout() {
    if (this._session) {
      delete this._session;
      delete localStorage[this.keyState];
      if (this.onLogoutSuccess) {
        this.onLogoutSuccess.next();
      }
    } else {
      this.onLogoutFail.next();
    }
  }

  get isExpire(): boolean {
    return !this.isValid;
  }

  get isValid(): boolean {
    if (this._session) {
      return true;
    } else {
      return false;
    }
  }

  getScope() {
    return { ...this._session.scope };
  }

  getToken() {
    if (this._session) {
      const [type, content, sign] = this._session.access_token.split('.');
      return {
        type: JSON.parse(atob(type)),
        content: JSON.parse(atob(content)),
        sign
      };
    }
  }

  get session() {
    return this._session
  }
}
