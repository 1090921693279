import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Pagination } from "../../_class/pagination";
import { DebugsService } from "../../_service/debugs.service";
import { Debug } from 'src/app/_class/debug';
import { AuthService } from 'src/app/_service/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-debug-admin',
  templateUrl: './debug-admin.component.html',
  styleUrls: ['./debug-admin.component.scss']
})
export class DebugAdminComponent implements OnInit, OnDestroy {
  data: Debug[] = []
  debug: Debug;
  page: Pagination = new Pagination({ expand: ['device'], sort: '-id', perPage: 500 })
  showSearch = false;
  search: any

  constructor(
    private authService: AuthService,
    private debugsService: DebugsService,
    private modalService: NgbModal,
    private toastrservice: ToastrService
  ) { }

  subLogin: Subscription = null

  async ngOnInit() {
    this.subLogin = this.authService.onLoginSuccess.subscribe(async () => {
      this.data = await this.getData()
    })
    if (this.authService.isValid) {
      this.data = await this.getData()
    }
  }

  ngOnDestroy() {
    if (this.subLogin != null) {
      this.subLogin.unsubscribe();
    }
  }

  async getData() {
    const { body, headers } = await this.debugsService.getDebugs(this.page.params).toPromise();
    this.page.Load(headers)
    return body;
  }

  open(debug, content) {
    this.debug = debug
    this.modalService.open(content, { size: 'xl' })
  }

  dataIcon = {
    'Desktop': ['fas', 'desktop'],
    'Handheld': ['fas', 'mobile'],
    'WindowsPlayer': ['fab', 'windows'],
    'Android': ['fab', 'android'],
    'LinuxPlayer': ['fab', 'linux'],
    'OSXPlayer': ['fab', 'osx'],
    'WebGLPlayer': ['fab', 'chrome'],
    'LinuxEditor': ['fas', 'cat'],
    'WindowsEditor': ['fas', 'cat'],
    'OSXEditor': ['fas', 'cat'],
    'sort': ['fas', 'sort'],
    'sort-up': ['fas', 'sort-up'],
    'sort-down': ['fas', 'sort-down'],
    'default': ['fas', 'bug']
  }

  icon(name: string) {
    try {
      return this.dataIcon[name]
    } catch (error) {
      this.dataIcon['default']
    }
  }

  sort = {
    name: 'id',
    order: true,
    menu: [
      { sort: 'id', title: 'ID' },
      { sort: 'app_name', title: 'App' },
      { sort: 'unity_version', title: 'Unity' },
      { sort: 'app_company', title: 'Empresa' },
      { sort: 'dev_id', title: 'Dispositivo' },
      { sort: 'creado', title: 'Fecha' },
    ]
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? this.icon('sort-up') : this.icon('sort-down')
    } else {
      return this.icon('sort')
    }
  }

  async setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop
      this.sort.order = true
    } else {
      this.sort.order = !this.sort.order
    }
    this.page.page = 1
    this.page.sort = `${this.sort.order ? '-' : ''}${this.sort.name}`;
    this.data = await this.getData();
  }

  async onScroll() {
    if (this.page.total > this.data.length) {

      this.page.page++;
      this.data.push(...await this.getData())
    } else {
      console.log("lastPage")
    }

  }

  scrollState = {
    show: false,
    onShow: 400,
    onHide: 200
  }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if ((window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop) > this.scrollState.onShow) {
      this.scrollState.show = true;
    } else if (this.scrollState.show &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop)
      < this.scrollState.onHide) {
      this.scrollState.show = false;
    }
  }


  filtro(filer: any) {


    //console.log(filer)

    if (filer.app_company == null && filer.app_name == null && filer.app_platform == null && filer.app_version == null && filer.unity_version == null) {
      this.toastrservice.info("No a seleccionado ninguna opcion para filtrar")
      return;
    }

    this.search = filer;

   this.search.app_company

    console.log(this.search)

  }

  Limpiar(clean){
    this.search=clean;
  }



}
