import { Component, OnInit,HostListener } from '@angular/core';
import { DevicesService } from '../_service/devices.service';
import { Subscription } from 'rxjs';
import { Pagination } from '../_class/pagination';
import { AuthService } from '../_service/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  idDevice
  largo=0

  data:any=[]
  page: Pagination = new Pagination({ expand: ['device'], sort: '-id', perPage: 200 })

  constructor(
    private authService: AuthService,
    private devicesservice:DevicesService,
    private toastrservice:ToastrService,
  
  ) { }
  subLogin: Subscription = null
  async ngOnInit() {
    this.subLogin = this.authService.onLoginSuccess.subscribe(async () => {
      
    })
    if (this.authService.isValid) {
      this.data = await this.getData()
    }


  }
  async getData() {
    const { body, headers } = await this.devicesservice.getdevices(this.page.params).toPromise();
    this.page.Load(headers)
    return body;
  }

  async onScroll() {

    if (this.page.total > this.data.length) {

      this.page.page++;
      this.data.push(...await this.getData())
    } else {
      console.log("lastPage")
    }

  }
  actualizar(data){

    if (data.debug==0) {
      data.debug=1
    this.devicesservice.editar(data,data.id).subscribe({
      next: () => {

        window.alert("Dispositivo actalizado con exito")
      },
      error: error => {
        window.alert("error")
        console.log(Error);
        //console.log(this.form.value)
        //console.log(this.id)

      }
    });
    }
    else {
      data.debug=0
    this.devicesservice.editar(data,data.id).subscribe({
      next: () => {

        window.alert("usuario actalizado con exito")
      },
      error: error => {
        window.alert("error")
        console.log(Error);
        //console.log(this.form.value)
        //console.log(this.id)

      }
    });
    }
    

  }
  limpiar(){
    this.idDevice="";
    this.toastrservice.info("Campos limpios")
  }



  scrollState = {
    show: false,
    onShow: 400,
    onHide: 200
  }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if ((window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop) > this.scrollState.onShow) {
      this.scrollState.show = true;
    } else if (this.scrollState.show &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop)
      < this.scrollState.onHide) {
      this.scrollState.show = false;
    }
  }

}
