import { Component, OnInit, OnDestroy, HostListener, EventEmitter } from '@angular/core';
import { Pagination } from "../../_class/pagination";
import { UserService } from "../../_service/user.service";
import { User } from 'src/app/_class/user';
import { AuthService } from 'src/app/_service/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit, OnDestroy {

  idEditar:number;
  data: User[] = []
  user: User;
  page: Pagination = new Pagination({ sort: 'id' })
  showSearch = false;
  usuario

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private modalService: NgbModal
  ) { }

  subLogin: Subscription = null
  async ngOnInit() {
    this.subLogin = this.authService.onLoginSuccess.subscribe(async () => {
      this.data = await this.getData()
    })
    if (this.authService.isValid) {
      this.data = await this.getData()
    }
  }

  ngOnDestroy() {
    if (this.subLogin != null) {
      this.subLogin.unsubscribe();
    }
  }

  async getData() {
    const { body, headers } = await this.userService.getUsers(this.page.params).toPromise();
    this.page.Load(headers)
    return body;
  }

  
  delete(id) {

    var resultado = window.confirm("¿Esta seguro que desea eliminar a este usuario? ");
    if (resultado === true) {
      this.userService.eliminar(id).subscribe({
        next: () => {

          window.location.reload()

        },
        error: error => {
          console.log(error);
          window.alert('usuario no eliminado ' + error);

        }
      });
      window.alert('usuario eliminado');

    } else {
      window.alert('usuario no eliminado ');
      return;
    }



  }

  open(user, content) {
    this.user = user
    this.modalService.open(content, { size: 'xl' })

  }
  //ventana modal
  //-----------------------------------------------------------------
  contrasenia = "";
  idUsuario?;
  dataUsuario?;
  subModal: NgbModalRef;
  async verUsuario(content, datos, opt?) {


    this.dataUsuario = await this.userService.buscar(datos).toPromise();
    this.contrasenia = "";


    for (let index = 0; index < this.dataUsuario.password.length; index++) {
      this.contrasenia += '*';
    }
    this.subModal = this.modalService.open(content, opt)
  }

  close() {
    this.subModal.close();

  }

  editarUsuario(content,dato, opt?) {
    this.idEditar = dato;
    this.subModal = this.modalService.open(content, opt)

  }

  newUser(content, opt?) {


    this.subModal = this.modalService.open(content, opt)
 
  }

  limpiar(){
    this.usuario="";
  }






}
