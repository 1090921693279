<div class="row">
    <div class="col-12">
        <h3>Debug</h3>
        <table class="table table-bordered">
            <tr>
                <th>Numero</th>
                <td>{{debug.id}}</td>
            </tr>
            <tr>
                <th>Creación</th>
                <td>{{debug.creado*1000|date :'long'}}</td>
            </tr>
        </table>
    </div>
    <div class="col-12">
        <h3>Aplicación</h3>
        <table class="table table-bordered">
            <tr>
                <th>Nombre</th>
                <td>{{debug.app_name}}</td>
                <th>Versión</th>
                <td>{{debug.app_version}}</td>
            </tr>
            <tr>
                <th>Compañia</th>
                <td>{{debug.app_company}}</td>
                <th>Plataforma</th>
                <td>{{debug.app_platform}}</td>
            </tr>
        </table>
    </div>
    <div class="col-12">
        <h3>Dispositivo</h3>
        <table class="table table-bordered">
            <tr>
                <th>Id</th>
                <td>{{debug.dev_id}}</td>
                <th>Nombre</th>
                <td>{{debug.device?.name}} {{debug.device?.alter_name}}</td>
                <th>Tipo</th>
                <td>{{debug.device?.type}}</td>
            </tr>
            <tr>

                <th>Keycode</th>
                <td>{{debug.device?.keycode}}</td>
                <th>Opciones</th>
                <td colspan="2"><button class="btn btn-sm btn-primary" (click)="showTrc=!showTrc">Mostrar
                        rastro</button></td>
            </tr>
        </table>
    </div>
</div>
<div class="row pl-3">
    <h2>Filtrar log</h2>
    <form class="form-inline ml-2">

        <div class="form-group sm-3 mb-2">

            <input [(ngModel)]="busqueda" name="busqueda" type="text" class="form-control">

        </div>
        <a (click)="recargar()" class="btn btn-primary ml-2 mb-2 text-white">Limpiar</a>



    </form>


    <div class="custom-control custom-switch ml-3 mt-2">
        <input (click)="activar(false)" type="checkbox" class="custom-control-input" id="customSwitch1" checked 
            *ngIf="tiemporeal==true">
        <input (click)="activar(true)" type="checkbox" class="custom-control-input" id="customSwitch1"
            *ngIf="tiemporeal==false">
        <label class="custom-control-label" for="customSwitch1">Tiempo Real <span  *ngIf="tiemporeal==true" >Activado</span> <span  *ngIf="tiemporeal==false" >Desactivado</span></label>
    </div>
    <div class="custom-control custom-switch ml-3 mt-2">
        <input (click)="agrupardata=false" type="checkbox" class="custom-control-input" id="customSwitch2" checked 
            *ngIf="agrupardata==true">
        <input (click)="agrupardata=true" type="checkbox" class="custom-control-input" id="customSwitch2"
            *ngIf="agrupardata==false">
        <label class="custom-control-label" for="customSwitch2">Agrupar <span  *ngIf="agrupardata==true" >Activado</span> <span  *ngIf="agrupardata==false" >Desactivado</span></label>
    </div>
</div>
<div class="row" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
    <div class="col">
        <div class="table-responsive">
            <table *ngIf="agrupardata==false" class="table table-bordered container-fluid">
                <thead>
                    <tr>
                        <th style="width: 10px;">#</th>
                        <th [width]="120" [ngClass]="{ 'text-primary': sort.name == 'id' }"
                            [ngStyle]="{ cursor: 'pointer'}" (click)="setOrder('id')">
                            <fa-icon [icon]="iconOrder('id')"></fa-icon> Tiempo
                        </th>
                        <th [ngClass]="{ 'text-primary': sort.name == 'msg' }" [ngStyle]="{ cursor: 'pointer'}"
                            (click)="setOrder('msg')">
                            <fa-icon [icon]="iconOrder('msg')"></fa-icon> Mensaje
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of log | filter:busqueda; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{time(log.creado)}}</td>
                        <td >
                            <pre  
                                [ngClass]="textColor(log.type)"><fa-icon [icon]="icon(log.type)"></fa-icon> {{log.type}} {{log.msg}} <p *ngIf="showTrc" class="text-muted">{{log.trc}}</p></pre>
                        </td>
                    </tr>
                </tbody>
                
            </table>
            <table *ngIf="agrupardata==true" class="table table-bordered container-fluid">
                <thead>
                    <tr>
                        <th style="width: 10px;">#</th>
                        <th> Mensaje</th>
                        <th> Repetido</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of agrup | filter:busqueda; let i = index">
                        <td>{{i+1}}</td>
                        <td [ngClass]="textColor(log.type)"> <fa-icon [icon]="icon(log.type)"></fa-icon> {{log.type}} {{log.log }}</td>
                        <td>{{log.repetido}}</td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
</div>
<button [ngClass]="'no-hidden'" *ngIf="scrollState.show" class="btn btn-primary" (click)="scrollTop()">
    <fa-icon [icon]="['fas','arrow-up']"></fa-icon>
</button>