import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { DebugAdminComponent } from './debug/debug-admin/debug-admin.component';
import { UserAdminComponent } from './user/user-admin/user-admin.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { DebugViewComponent } from './debug/debug-view/debug-view.component';
import{DebugNewuserComponent} from './debug/debug-newuser/debug-newuser.component';
import { VerUsuarioComponent } from './ver-usuario/ver-usuario.component';
import { EditarUsuarioComponent } from './editar-usuario/editar-usuario.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { DevicesComponent } from './devices/devices.component';
import { ViwDebugDeviceComponent } from './viw-debug-device/viw-debug-device.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: '/debugs', pathMatch: "full" },
      {
        path: 'debugs',
        children: [
          { path: '', component: DebugAdminComponent },
          { path: ':id', component: DebugViewComponent },
          { path: ':id', component: DebugViewComponent },
        ]
      },
      { path: "users",
      children:[
        {path: '', component: UserAdminComponent},
        {path: ':id', component: VerUsuarioComponent},
        {path: 'editar/:id', component: EditarUsuarioComponent}
      ]
    },
      { path: "new-user", component: DebugNewuserComponent },
      {path: "empresas", component:EmpresasComponent},
      {
        path: "dispositivos",
        children: [
          { path: '', component:DevicesComponent },
          { path: ':id', component: ViwDebugDeviceComponent },
        ]
      },
    ]
  }, {
    path: "**",
    pathMatch: 'full',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
