import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from "./auth.service";
import { Device } from '../_class/device';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  resource = `${environment.endpoint}/device`
  constructor(private http: HttpClient, private auth: AuthService) { }

  getdevices(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}`}
    return this.http.get<any[]>(this.resource, { headers, params, observe: 'response' })
  }
  editar(data,pk?:HttpParams ){
    const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}` }
    return this.http.put(`${this.resource}/${pk}`,data,{ headers, observe: 'response' })
  }
  getdevice(pk: number, params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}` }
    return this.http.get<Device>(`${this.resource}/${pk}`, { headers, params })
  }
}
