<div class="container-fluid ">
  <div class="d-block container-xl">

    <h2>Buscar por Id</h2>
    <form class="form-inline">

      <div class="form-group sm-3 mb-2">

        <input [(ngModel)]="idDevice" name="idDevice" type="text" class="form-control" placeholder="id">

      </div>
    
      <a (click)="limpiar()" class="btn btn-primary ml-2 mb-2 text-white">Limpiar</a>

    </form>

    <div *ngFor="let data of data |  filter:idDevice" class="card mr-4 float-left mt-4" style="width: 15rem;">

      <div class="card-img-top container">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
          <path
            d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z" />
        </svg>
      </div>

      <div class="card-body" >
        <h5 class="card-title">Dispositivo: </h5>
        <p>{{data.name}}</p>
        <h5 class="card-title">Tipo:</h5>
        <p>{{data.type}}</p>
        <div class="custom-control custom-switch">
          <input (click)="actualizar(data)" type="checkbox" class="custom-control-input" id="customSwitch{{data.id}}"
            *ngIf="data.debug==1" checked>
          <input (click)="actualizar(data)" type="checkbox" class="custom-control-input" id="customSwitch{{data.id}}"
            *ngIf="data.debug==0">
          <label class="custom-control-label" for="customSwitch{{data.id}}">Estado del Dipositivo</label>
        </div>
        <button class="btn btn-primary" [routerLink]="['/dispositivos',data.id]">Ver debug</button>
        <p class="float-right">Id: {{data.id}}</p>
      </div>
      
    </div>
    
  </div>
</div>
<div class="float-right" infinite-scroll (scrolled)="onScroll()">
  <p></p>
</div>
<button [ngClass]="'no-hidden'" *ngIf="scrollState.show" class="btn btn-primary" (click)="scrollTop()">
  <fa-icon [icon]="['fas','arrow-up']"></fa-icon>
</button>
