import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from "./auth.service";
import { User } from "../_class/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  resource = `${environment.endpoint}/user`
  constructor(private http: HttpClient, private auth: AuthService) { }

  getUsers(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}`}
    return this.http.get<User[]>(this.resource, { headers, params, observe: 'response' })
  }

  Create(data) {

      const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}`};
      return this.http.post(this.resource, data,{headers});
  }

  eliminar(pk){
      const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}` }
      return this.http.delete(`${this.resource}/${pk}`,{ headers})

  }

  buscar(pk?: HttpParams | { [param: string]: string | string[]; }){
      const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}` }
      return this.http.get(`${this.resource}/${pk}`,{ headers})
  }

  editar(data,pk?:HttpParams ){
    const headers = { 'Authorization': `${this.auth.session.type} ${this.auth.session.access_token}` }
    return this.http.put(`${this.resource}/${pk}`,data,{ headers, observe: 'response' })
  }

}
