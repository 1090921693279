<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-primary ">
    <a class="navbar-brand" routerLink="">
        <fa-icon [icon]="['fas','mug-hot']"></fa-icon> Qualitat <small>for Developers</small>
    </a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed" aria-controls="collapse">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-between" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" routerLink="/debugs" routerLinkActive="active">
                    <fa-icon [icon]="['fas','bug']"></fa-icon> Registros
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/users" routerLinkActive="active">
                    <fa-icon [icon]="['fas','user-cog']"></fa-icon> Usuarios
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/empresas" routerLinkActive="active">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                        <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                      </svg> Empresas
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/dispositivos" routerLinkActive="active">
                    <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
                        <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z"/>
                      </svg> 
                     Dispositivos
                </a>
            </li>
        </ul>
        <ul class="navbar-nav">
            <span class="navbar-text" style="pointer-events: none;">
                Bienvenido {{username}}
            </span>
            <li ngbDropdown display="dynamic" class="nav-item dropdown">
                <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <fa-icon [icon]="['fas','user-circle']"></fa-icon>
                </a>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Cambiar Perfil</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" [routerLink]="" (click)="logout()">Cerrar sesión</a>
                </div>
            </li>
        </ul>
    </div>
</nav>