<h2>Administración <small>Debugs</small><button class="btn btn-primary float-right" (click)="showSearch=!showSearch">
    <fa-icon [icon]="['fas','search']"></fa-icon> Busqueda avanzada
 </button></h2>
<div class="row" *ngIf="showSearch">
 <div class="col">
     <app-debug-search (dataFilter)="filtro($event)" (limpio)="Limpiar($event)" ></app-debug-search>
 </div>
</div>

<div class="row" infinite-scroll  (scrolled)="onScroll()">
 <div class="col">
     <div class="table-responsive">
         <table class="table table-bordered table-striped table-hover">
             <thead>
                 <tr>
                     <th scope="col" *ngFor="let item of sort.menu"
                         [ngClass]="{ 'text-primary': sort.name == item.sort }" [ngStyle]="{ cursor: 'pointer'}"
                         (click)="setOrder(item.sort)">
                         <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
                     </th>
                 </tr>
             </thead>
             <tbody *ngIf="search==null">
                 <tr *ngFor="let debug of data" [routerLink]="['/debugs',debug.id]" [ngStyle]="{ cursor: 'pointer'}">
                     <td>{{debug.id}}</td>
                     <td>
                         <fa-icon [icon]="icon(debug.app_platform)"></fa-icon>
                         {{debug.app_name}}
                         {{debug.app_version}}
                     </td>
                     <td>
                         <fa-icon [icon]="['fab','unity']"></fa-icon>
                         {{debug.unity_version}}
                     </td>
                     <td>{{debug.app_company}}</td>
                     <td>
                         <fa-icon [icon]="icon(debug.device.type)"></fa-icon>
                         {{debug.device.name}}
                         {{debug.device.alter_name}}
                     </td>
                     <td>{{debug.creado*1000|date:"dd-MM-yyyy"}}  {{ debug.creado*1000|date:"HH:mm:ss"}} </td>
                 </tr>
             </tbody>
             <tbody *ngIf="search!=null" >
                <tr *ngFor="let debug of data |  filter:search.app_name |  filter:search.app_version |  filter:search.app_company |  filter:search.unity_version |  filter:search.app_platform"  [routerLink]="['/debugs',debug.id]" [ngStyle]="{ cursor: 'pointer'}">
                    <td>{{debug.id}}</td>
                    <td>
                        <fa-icon [icon]="icon(debug.app_platform)"></fa-icon>
                        {{debug.app_name}}
                        {{debug.app_version}}
                    </td>
                    <td>
                        <fa-icon [icon]="['fab','unity']"></fa-icon>
                        {{debug.unity_version}}
                    </td>
                    <td>{{debug.app_company}}</td>
                    <td>
                        <fa-icon [icon]="icon(debug.device.type)"></fa-icon>
                        {{debug.device.name}}
                        {{debug.device.alter_name}}
                    </td>
                    <td>{{debug.creado*1000|date}}</td>
                </tr>
            </tbody>
         </table>
     </div>
 </div>
</div>
<button [ngClass]="'no-hidden'" *ngIf="scrollState.show" class="btn btn-primary" (click)="scrollTop()">
 <fa-icon [icon]="['fas','arrow-up']"></fa-icon>
</button>
