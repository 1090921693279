<div class="row">
    <div class="col-12">
        <h3>Datos del usuario</h3>
        <table class="table table-bordered">
            <tr>
                <th>Nombre</th>
                <td>{{data.name}}</td>
            </tr>
            <tr>
                <th>Nombre de Usuario</th>
                <td>{{data.username}}</td>
            </tr>
            <tr>
                <th>Contraseña</th>
                <td>{{data.password}}</td>
            </tr>
        </table>
    </div>
    <div class="col-12">
        <h3>Fecha</h3>
        <table class="table table-bordered">
            <tr>
                <th>Fecha de creacion</th>
                <td>{{data.creado|date}}</td>
            </tr>
            <tr>
                <th>Fecha de Modificacion</th>
                <td>{{data.modificado|date}}</td>
            </tr>
        </table>
        <button  (click)="goBack()"  class="btn btn-danger">
            volver
        </button>   
    </div>
    
</div>
