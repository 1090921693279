import { Component, EventEmitter, Input, OnInit,Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/_service/user.service';
import { User } from '../_class/user';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss']
})
export class EditarUsuarioComponent implements OnInit {
  form: FormGroup;
  @Input() id ;
  data: User;

  @Output() close:EventEmitter<any> = new EventEmitter(); 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userservice: UserService,
  ) { }

  ngOnInit() {
    
    this.getData();
  }

  async getData() {
    this.data = await this.userservice.buscar(this.id).toPromise();

   

    this.form = this.formBuilder.group({
      name: [this.data.name , Validators.required],
      username: [this.data.username, Validators.required],
      password: [this.data.password, Validators.required]
    });
  }



  onSubmit() {
    this.userservice.editar(this.form.value,this.id).subscribe({
      next: () => {

        window.alert("usuario actalizado con exito")
        window.location.reload();


      },
      error: error => {
        window.alert("error")
        console.log(Error);
        //console.log(this.form.value)
        //console.log(this.id)

      }
    });
  }

}
