import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_service/user.service';
import { User } from '../_class/user';


@Component({
  selector: 'app-ver-usuario',
  templateUrl: './ver-usuario.component.html',
  styleUrls: ['./ver-usuario.component.scss']
})
export class VerUsuarioComponent implements OnInit {
  id=null;
  data?;

  constructor(
   private route:ActivatedRoute,
   private userservice:UserService,
   private router:Router,
  ) { 
  }

  ngOnInit() {
   this.id= this.route.snapshot.paramMap.get('id');
   this.getData();
   
  }



  async getData(){
   this.data = await this.userservice.buscar(this.id).toPromise();
  
    
  }
  
  goBack(){

    this.router.navigate(['/users'])

  }
}
