<div class="card mb-2">
  <div class="card-body">
    <form (ngSubmit)="onSubmit()">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="appName">Aplicación</label>
          <ng-select name="appName" [items]="form.select.app_name" [multiple]="false" [closeOnSelect]="true"
            [hideSelected]="true" placeholder="Selecciona el nombre de la aplicación" [(ngModel)]="form.input.app_name">
          </ng-select>
        </div>
        <div class="form-group col-md-2">
          <label for="appName">Versión</label>
          <ng-select name="appName" [items]="form.select.app_version" [multiple]="false" [closeOnSelect]="true"
            [hideSelected]="true" placeholder="Selecciona la versión" [(ngModel)]="form.input.app_version">
          </ng-select>
        </div>
        <div class="form-group col-md-6">

          <label for="appCompany">Empresa</label>
          <ng-select name="appCompany" [items]="form.select.app_company" [multiple]="false" [closeOnSelect]="true"
            [hideSelected]="true" placeholder="Selecciona el nombre de la empresa" [(ngModel)]="form.input.app_company">
          </ng-select>
        </div>
        <div class="form-group col-md-6">

          <label for="appPlatform">Plataforma</label>
          <ng-select name="appPlatform" [items]="form.select.app_platform" [multiple]="false" [closeOnSelect]="true"
            [hideSelected]="true" placeholder="Selecciona el nombre de la plataforma"
            [(ngModel)]="form.input.app_platform">
          </ng-select>
        </div>
        <div class="form-group col-md-6">
          <label for="unityVersion">Versión de Unity</label>
          <ng-select name="unityVersion" [items]="form.select.unity_version" [multiple]="false" [closeOnSelect]="false"
            [hideSelected]="true" placeholder="Selecciona la versión de unity" [(ngModel)]="form.input.unity_version">
          </ng-select>
        </div>
      </div>
      <!-- <div class="form-group">
        <label for="inputAddress">Address</label>
        <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
      </div>
      <div class="form-group">
        <label for="inputAddress2">Address 2</label>
        <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor">
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputCity">City</label>
          <input type="text" class="form-control" id="inputCity">
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">State</label>
          <select id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>...</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="inputZip">Zip</label>
          <input type="text" class="form-control" id="inputZip">
        </div>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="gridCheck">
          <label class="form-check-label" for="gridCheck">
            Check me out
          </label>
        </div>
      </div> -->

      <button type="submit" class="btn btn-primary float-right ml-2">Buscar</button>
      
    </form>
    <button class="btn btn-secondary float-right" (click)="(limpiarfiltro())" >Limpiar</button>
  </div>
</div>